<template>
  <div class="mt-5" v-show="wordList.length !== 0">
    <div class="word-panel-header mt-2 mb-1">
      <v-subheader style="vertical-align: middle">
        <v-icon size="1.1rem" dense class="mr-2" style="display: inline">
          mdi-calendar-clock-outline
        </v-icon>
        <span style="color: #aaaaaa; line-height: 1">{{ date }}</span>
      </v-subheader>
      <v-switch dense v-model="showWords" color="indigo lighten-2"></v-switch>
    </div>

    <v-expansion-panels
      v-show="showWords"
      class="rounded-lg"
      v-model="panelDetails"
      accordion
      multiple
    >
      <v-expansion-panel v-for="item in wordList" :key="item.id">
        <v-expansion-panel-header class="word-header">
          <div style="display: flex; align-items: center">
            <v-progress-circular
              v-show="item.familiarity === null || item.familiarity < 20"
              :size="16"
              :width="8"
              rotate="-90"
              color="indigo lighten-3"
              :value="item.familiarity === null ? 0 : item.familiarity"
            ></v-progress-circular>
            <v-progress-circular
              v-show="
                item.familiarity !== null &&
                20 <= item.familiarity &&
                item.familiarity < 80
              "
              :size="16"
              :width="8"
              rotate="-90"
              color="deep-orange lighten-3"
              :value="item.familiarity"
            ></v-progress-circular
            ><v-progress-circular
              v-show="item.familiarity !== null && item.familiarity >= 80"
              :size="16"
              :width="8"
              color="green lighten-3"
              rotate="-90"
              :value="item.familiarity"
            ></v-progress-circular>
            <span class="ml-2" style="line-height: 1">{{ item.word }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="word-detail">
            <div class="left">
              <span v-show="item.phonetic"
                ><van-tag class="mr-2">/ {{ item.phonetic }} /</van-tag></span
              ><span class="translation" v-show="item.translation">{{
                formatTranslate(item.translation)
              }}</span>
            </div>
            <div class="right">
              <v-btn
                class="rounded-lg"
                small
                dense
                text
                color="indigo lighten-2"
                style="min-height: 1.5rem"
                @click="itemClick(item)"
              >
                更多
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { Tag } from "vant";
import { isEmpty } from "@/utils/common";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "wordPanel",
  mixins: [buttonVibrate],
  components: {
    vanTag: Tag,
  },
  props: {
    searchWord: {
      type: String,
      default: "",
    },
    wordList: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: "更早以前",
    },
  },
  computed: {
    formatTranslate() {
      return function (text) {
        if (!isEmpty(text)) {
          let showText = text.replace(/\n/g, " ").replace(/\\n/g, " ");
          if (text.length > 30) {
            showText = showText.substring(0, 30) + " ...";
          }
          return showText;
        }
        return "";
      };
    },
  },
  watch: {
    wordList: {
      handler() {
        this.$nextTick(() => {
          this.hideAll();
          // console.log("数据变动");
        });
      },
      deep: true,
    },
  },
  data: () => ({
    showWords: true,
    panelDetails: [],
  }),
  methods: {
    isEmpty,
    showAll() {
      this.panelDetails = this.wordList.map((k, i) => i);
    },
    // Reset the panel
    hideAll() {
      this.panelDetails = [];
    },
    //点击搜索项
    itemClick(item) {
      this.zzzShort();
      let word = item.word;
      let id = item.id;
      this.$router.push({
        path: "/word_detail",
        query: {
          word: word,
          id: id,
        },
      });
    },
  },
  created() {
    // this.showAll();
    this.hideAll();
  },
};
</script>

<style lang="scss" scoped>
.word-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
}
.word-header {
  min-height: unset !important;
}
.word-detail {
  margin-right: -12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    flex: 1;

    > span {
      margin-right: 10px;
    }

    .translation {
      font-size: 0.9rem;
      color: #888888;
    }
  }

  .right {
    height: 100%;
    flex: 0 0 4.1rem;
    display: flex;
    justify-content: right;
  }
}
</style>
